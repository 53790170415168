import React from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import HistoryList from '../components/account/billing-history-list'

const AccountBilling = ({ location }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  return (
    <Wrap>
      <SEO title="Billing History" />
      <FocusHeader
        title="Account"
        subTitle="Billing History"
        titleLink="/account"
        backUrl={backUrl}
      />
      <FocusBody>
        <Title>Billing History</Title>
        <HistoryList />
      </FocusBody>
    </Wrap>
  )
}

const condition = authUser => !!authUser
const AccountWithAuth = compose(withAuthorization(condition))(AccountBilling)
export default AccountWithAuth
