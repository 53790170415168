import React from 'react'
import styled, { keyframes } from 'styled-components'

export default () => {
  return (
    <Wrap>
      <Pacman />
      <Dot />
    </Wrap>
  )
}

const width = 30
const height = width / 2
const time = `0.4s`

const animationUp = keyframes`
  0%, 100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-30deg);
  }
`

const animationDown = keyframes`
  0%, 100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(30deg);
  }
`

const animationRightLeft = keyframes`
  100% {
    margin-left: -1px;
  }
`

const Wrap = styled.div`
  position: relative;
  height: ${width}px;
  margin-top: 1em;
`

const Pacman = styled.div`
  :before,
  :after {
    content: '';
    position: absolute;
    background: ${props => props.theme.colors.highlight1};
    width: ${width}px;
    height: ${height}px;
    left: ${width / 2}px;
    top: 50%;
    margin-left: -${width / 2}px;
  }
  :before {
    margin-top: -${height}px;
    border-radius: ${height}px ${height}px 0 0;
    animation: ${animationUp} ${time} infinite;
  }
  :after {
    margin-top: -1px;
    border-radius: 0 0 ${height}px ${height}px;
    animation: ${animationDown} ${time} infinite;
  }
`

const Dot = styled.div`
  position: absolute;
  left: ${width / 2}px;
  top: 48%;
  width: 7px;
  height: 7px;
  margin-top: -${height / 10}px;
  margin-left: 30px;
  border-radius: 50%;
  background: ${props => props.theme.colors.greyDark5};
  z-index: -1;
  box-shadow: 20px 0 0 ${props => props.theme.colors.greyDark5},
    40px 0 0 ${props => props.theme.colors.greyDark5},
    60px 0 0 ${props => props.theme.colors.greyDark5},
    80px 0 0 ${props => props.theme.colors.greyDark5},
    100px 0 0 ${props => props.theme.colors.greyDark5};
  animation: ${animationRightLeft} ${time} infinite;
`

/*
$width: 50px;
$height: $width/2;
$green: #00c7b2;
$grey: #ccc;
$time: 0.4s;

@mixin animation($name) {
  @keyframes #{$name} {@content;}
}

@include animation(up) {
  0%, 100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-30deg);
  }
}

@include animation(down) {
  0%, 100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(30deg);
  }
}

@include animation(r-to-l) {
  100% {
    margin-left: -1px;
  }
}

@mixin use-animation($name, $time){
  -webkit-animation: $name $time infinite;
  -moz-animation: $name $time infinite;
  -o-animation: $name $time infinite;
  animation: $name $time infinite;
}


body{

  .pacman:before, .pacman:after{
    content: '';
    position: absolute;
    background: $green;
    width: $width;
    height: $height;
    left: $width/2;
    top: 50%;
    margin-left: - $width/2;
    margin-top: - $height;
    border-radius: $height $height 0 0;
    @include use-animation(up, $time);

   
  }

  .pacman:after {
    margin-top: -1px;
    border-radius: 0 0 $height $height;
    @include use-animation(down, $time);
  }

  .pacman__dot{
    position: absolute;
    left: $width/2;
    top: 50%;
    width: $height/5;
    height: $height/5;
    margin-top: - $height/10;
    margin-left: 30px;
    border-radius: 50%;
    background: $grey;
    z-index: -1;
    box-shadow: 20px 0 0 $grey,
                40px 0 0 $grey,
                60px 0 0 $grey,
                80px 0 0 $grey,
                100px 0 0 $grey;
    @include use-animation(r-to-l, $time);
  }
}
*/
