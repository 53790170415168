import React, { useState, useEffect } from 'react'
import { withFirebase } from '../firebase'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Copy } from '../page/components'
import { Flex, Box } from 'reflexbox'

import { Errors } from '../page/components'
import ListItem from './billing-history-list-item'
import Loader from '../global/loader'

const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: null,
  fetchErrors: [],
}

const List = ({ firebase }) => {
  if (firebase && firebase.auth && firebase.auth.currentUser) {
    const [status, setStatus] = useState(INITIAL_STATE)
    const { fetching, fetched, data, fetchErrors } = status
    const user = firebase.auth.currentUser

    useEffect(() => {
      fetch()
    }, [])

    const fetch = () => {
      setStatus(prevState => ({
        ...prevState,
        fetching: true,
      }))

      const func = firebase.functions.httpsCallable('dbBillingHistoryList')
      const funcData = {
        userId: user.uid,
      }

      func(funcData)
        .then(res => {
          console.log('✔ Fetched', res)

          setStatus(prevState => ({
            ...prevState,
            fetching: false,
            fetched: true,
            data: res.data,
            fetchErrors: [],
          }))
        })
        .catch(error => {
          const { message } = error
          console.log('Error', message)

          setStatus(prevState => ({
            ...prevState,
            fetching: false,
            fetched: true,
            data: null,
            fetchErrors: [
              `We had a problem getting your billing history! ${message}`,
            ],
          }))
        })
    }

    // console.log('1. >>>', user)
    // console.log('2. >>>', status)

    let errorContent
    if (fetchErrors && fetchErrors.length > 0) {
      errorContent = (
        <Flex flexWrap={['wrap']} width={`100%`} mb={[0]}>
          <Box width={[1, 1]}>
            <Errors>
              {fetchErrors.map((error, i) => {
                return <p key={`error-${i}`}>{error}</p>
              })}
            </Errors>
          </Box>
        </Flex>
      )
    }

    if (fetching && !fetched) {
      return <Loader />
    } else if (fetched && fetchErrors.length > 0) {
      return errorContent
    } else if (fetched && data) {
      if (data.stripe) {
        const list = data.stripe.data.map((charge, index) => (
          <ListItem key={index} charge={charge} />
        ))
        return (
          <Flex flexWrap="nowrap">
            <Box width="100%">
              <Copy>
                <p>
                  Charges for your subscription are detailed below. If you have
                  any queries please visit{' '}
                  <Link to="/account-support">support</Link>.
                  <br />
                  <br />
                </p>
              </Copy>
              <Wrap>{list}</Wrap>
            </Box>
          </Flex>
        )
      } else {
        return (
          <Flex flexWrap="nowrap">
            <Box width="100%">
              <Copy>
                <p>
                  There doesn't seem to be a billing account for this account.
                </p>
              </Copy>
            </Box>
          </Flex>
        )
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export default withFirebase(List)

const Wrap = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.greyDark2};
`
