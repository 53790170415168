import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

export default ({ charge }) => {
  console.log('>>>', charge)

  const { description, receipt_url, amount, currency, created } = charge

  let symbol = '$'
  if (currency === 'gbp') {
    symbol = '£'
  }

  const momentDate = moment.unix(created).format('Do MMM YYYY')

  const visualAmount = amount / 100

  return (
    <Item>
      <span>
        <Date>{momentDate}</Date>
        <Amount>
          {symbol}
          {visualAmount}
          {currency.toUpperCase()}
        </Amount>
        {description}
      </span>
      <a href={receipt_url} target="_blank" rel="noopener noreferrer">
        Receipt
      </a>
    </Item>
  )
}

const Item = styled.div`
  border-top: 1px solid ${props => props.theme.colors.greyDark2};
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.pageCopy()};
  a {
    color: ${props => props.theme.colors.white};
  }
`

const Date = styled.span`
  display: inline-block;
  margin-right: 1em;
`

const Amount = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 1em;
`
